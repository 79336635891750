import Benefits from './Benefits'
import Cover from './Cover'
import Informations from './Informations'
import OurTeam from './OurTeam'
import Quotation from './Quotation'
import Slogan from './Slogan'
import Slogan2 from './Slogan2'
import { ContainerVideos } from './styles'
import Testimonials from './Testimonials'
import Video from './Video'

export default function Home() {
  return (
    <>
      <Cover />
      <Quotation />

      <Benefits />
      <Slogan />

      <ContainerVideos>
        <Video link="https://www.youtube.com/watch?v=oIbjgk1nI-w" />
        <Video link="https://www.youtube.com/watch?v=ko_VPVd5pKw" />
        <Video link="https://www.youtube.com/watch?v=KTdj4oRUSYg" />
      </ContainerVideos>

      <Informations />
      <Slogan2 />

      <Testimonials />
      <OurTeam />
    </>
  )
}
